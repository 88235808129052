/**
 * Foursource page
 * https://foursource.com/about-us
 */

import * as React from 'react';
import { withTrans } from '../i18n/withTrans';

import { pageTexts } from '../content/about-us';
import { contactTexts } from '../content/pages/contact';
import { valuesTexts } from '../content/pages/values';

// components
import Layout from '../components/layout';
import TextImgBackgroundFull from '../components/textImgBackgroundFull';
import TitleSeparator from '../components/titleSeparator';
import PhotoCardHover from '../components/photoCardHover';
import Contact from '../components/_page/_contact';
import IconText from '../components/iconText';
import Values from '../components/_page/_values';

class aboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.contactTexts = contactTexts(this.t);
    this.valuesTexts = valuesTexts(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName='about-us' whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          <div className='paddingSidesBigger pt-30 pb-50 text-center'>
            <TitleSeparator info={this.text.ourMission}></TitleSeparator>
            <div className='d-flex mt-100'>
              {this.text.topIcons.map((icon, i) => (
                <IconText key={i} info={icon}></IconText>
              ))}
            </div>
          </div>
          <div className='paddingSidesBig backgroundGrey topBottomDiagonal pt-30 text-center'>
            <div className='pt-100 pb-100'>
              <TitleSeparator info={this.text.ourLeadership}></TitleSeparator>
              <div className='container'>
                <div className='row row-cols-2 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 pt-30'>
                  {this.text.leads.map((lead, i) => (
                    <PhotoCardHover key={i} info={lead}></PhotoCardHover>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Values text={this.valuesTexts}></Values>
          <div className='backgroundGrey topBottomDiagonal paddingSidesBigger pt-30 pb-50 text-center'>
            <div className='pt-100 pb-100'>
              <TitleSeparator info={this.text.globalApproach}></TitleSeparator>
            </div>
          </div>
          <Contact text={this.contactTexts.pressInquires}></Contact>
          <Contact text={this.contactTexts.team}></Contact>
        </Layout>
      </>
    );
  }
}

export default withTrans(aboutPage);
