// images
import RocketIcon from '../images/icon/rocketGreen.svg';
import Globe from '../images/icon/countriesIcon.svg';
import People from '../images/icon/peopleGreen.svg';
import TopBackground from '../images/about-us-new.jpeg';
import Jonas from '../images/about-us/jonas.jpg';
import Kerstin from '../images/about-us/kerstin.jpg';
import Bruno from '../images/about-us/bruno.png';
import Pedro from '../images/about-us/pedro.png';
import Philipp from '../images/about-us/philipp.png';
import Carlao from '../images/about-us/carlao.png';
import Susanne from '../images/about-us/susanne.jpg';
import Izzy from '../images/about-us/izzy.png';
import Filipe from '../images/about-us/filipe.png';
import Vitor from '../images/about-us/vitor.png';
import Amaral from '../images/about-us/amaral.png';

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t('about-us.topTitle'),
        colorClass: 'colorLight',
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t('about-us.imgAlt'),
      },
    },
    topIcons: [
      {
        title: {
          text: t('about-us.launchTitle'),
          isNum: true,
          colorClass: 'colorMain',
        },
        subtitle: {
          text: t('about-us.launchSubtitle'),
          colorClass: 'colorDark text-center',
        },
        icon: {
          src: RocketIcon,
          alt: t('about-us.rocketAlt'),
        },
      },
      {
        title: {
          text: t('about-us.employeesTitle'),
          isNum: true,
          colorClass: 'colorMain',
        },
        subtitle: {
          text: t('about-us.employeesSubtitle'),
          colorClass: 'colorDark',
        },
        icon: {
          src: Globe,
          alt: t('about-us.globeAlt'),
        },
      },
      {
        title: {
          text: t('about-us.recruitingTitle'),
          textBefore: '>',
          isNum: true,
          colorClass: 'colorMain',
        },
        subtitle: {
          text: t('about-us.recruitingSubtitle'),
          colorClass: 'colorDark text-center',
        },
        icon: {
          src: People,
          alt: t('asbx.recruitingAlt'),
        },
      },
    ],
    ourMission: {
      title: {
        text: t('about-us.ourMission'),
        colorClass: 'colorDark',
        extraClass: 'font40',
      },
      text: {
        text: t('about-us.missingTransparency'),
        colorClass: 'colorDark',
        textType: 'paragraph',
      },
      separator: true,
    },
    globalApproach: {
      title: {
        text: t('about-us.globalApproach'),
        colorClass: 'colorDark',
        extraClass: 'font40',
      },
      text: {
        text: t('about-us.growingCountries'),
        colorClass: 'colorDark',
        textType: 'paragraph',
      },
      separator: true,
    },
    ourLeadership: {
      title: {
        text: t('about-us.leadershipTeam'),
        colorClass: 'colorDark',
        extraClass: 'font40',
      },
      separator: true,
    },
    leads: [
      {
        img: {
          src: Jonas,
          alt: t('about-us.jonasAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        text: {
          name: t('about-us.jonas'),
          position: t('about-us.jonasTitle'),
        },
      },
      {
        img: {
          src: Carlao,
          alt: t('about-us.carlaoAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        text: {
          name: t('about-us.carlao'),
          position: t('about-us.carlaoTitle'),
        },
      },
      {
        img: {
          src: Philipp,
          alt: t('about-us.philippAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        text: {
          name: t('about-us.philipp'),
          position: t('about-us.philippTitle'),
        },
      },
      {
        img: {
          src: Kerstin,
          alt: t('about-us.kerstinAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        text: {
          name: t('about-us.kerstin'),
          position: t('about-us.kerstinTitle'),
        },
      },
      {
        img: {
          src: Susanne,
          alt: t('about-us.susanneAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        text: {
          name: t('about-us.susanne'),
          position: t('about-us.susanneTitle'),
        },
      },
      {
        img: {
          src: Pedro,
          alt: t('about-us.pedroAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        text: {
          name: t('about-us.pedro'),
          position: t('about-us.pedroTitle'),
        },
      },
      {
        img: {
          src: Izzy,
          alt: t('about-us.izzyAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        text: {
          name: t('about-us.izzy'),
          position: t('about-us.izzyTitle'),
        },
      },
      {
        img: {
          src: Bruno,
          alt: t('about-us.brunoAlt'),

          extraClass: 'w-100',
        },
        separator: true,
        text: {
          name: t('about-us.bruno'),
          position: t('about-us.brunoTitle'),
        },
      },
      {
        img: {
          src: Vitor,
          alt: t('about-us.vitorAlt'),

          extraClass: 'w-100',
        },
        separator: true,
        text: {
          name: t('about-us.vitor'),
          position: t('about-us.vitorTitle'),
        },
      },
      {
        img: {
          src: Amaral,
          alt: t('about-us.amaralAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        text: {
          name: t('about-us.amaral'),
          position: t('about-us.amaralTitle'),
        },
      },
      {
        img: {
          src: Filipe,
          alt: t('about-us.filipeAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        text: {
          name: t('about-us.filipe'),
          position: t('about-us.filipeTitle'),
        },
      },
    ],
  };
};
